<template>
  <q-page-container>
    <ServicesMain />
  </q-page-container>
</template>

<script>
// @ is an alias to /src
import ServicesMain from '@/components/services/ServicesMain.vue'

export default {
  components: {
    ServicesMain
  }
}
</script>
