import { defineStore } from 'pinia'
import axios from 'axios'
import { useCookies } from "vue3-cookies"
//import router from '@/router'

const { cookies } = useCookies()

export const useAuthStore = defineStore({
  id: 'auth',
  // fresh state
  state: () => ({
    // initialize state from localstorage to keep user logged in
    isAuthorized: cookies.get('authorized'),
    user: cookies.get('user'),
    name: cookies.get('firstname'),
    userLoc: cookies.get('loc'),
    userOrg: cookies.get('org'),
    activeDisease: cookies.get('activeDisease'),
    // routing
    userComponents: cookies.get('components'),
    // url
    returnUrl: localStorage.getItem('returnUrl')
  }
  ),
  // actions
  actions: {
    async login(username, password) {
      
      try {
        const res = await axios.post('/dssbe/auth', 
        {   
          username: username, 
          password: password 
        }, 
        {   
          headers: {
            "Content-Type": "multipart/form-data",
            "Cache-Control": "no-cache",
            "Pragma": "no-cache",
            "Expires": "0",
          },
        })
        
        if (res.data.data) {
          // store user deets in localstorage to keep user logged in
          cookies.set('authorized', true, 0)                       // set as logged in or authorized
          cookies.set('user', username)                         // store user name
          cookies.set('firstname', res.data.data.mdata[0].firstName)   // store first name
          cookies.set('loc', res.data.data.mdata[0].orgPSGC)     // region abbreviation
          cookies.set('org', res.data.data.mdata[0].orgID)     // region abbreviation
          

          cookies.set('tok', res.data.data.access_token)
          cookies.set('tok_exp', res.data.data.expires_in)
          
          const allcomponents = res.data.data.mdata[1].map(d => ({        // dynamic cards and routing
            name: d.componentName,
            icon: d.componentIcon,
            desc: d.componentDesc,
            path: d.componentPath,
            status: d.componentStatus,
            disease: d.componentDisease
          }))
          
          cookies.set('components', JSON.stringify(allcomponents))
          
          // returns the userTypeID
          return Promise.resolve(res.data.data.mdata[0].userTypeID)
        } 
        
        return Promise.reject(new Error(res.data.message))
        
      } catch (error) {
        return Promise.reject(new Error(error))
      }	
    },
    
    switchDisease(selected) {
      cookies.set('activeDisease', selected)
    },

    async getComponents() {
      try {

        const formData = new FormData()
        formData.append("email", decodeURIComponent(cookies.get('user')))
        formData.append("disease", decodeURIComponent(cookies.get('disease')))

        const resCom = await axios.post('/getComponents', 
        formData,
        {
          baseURL: `${axios.defaults.baseURL}diwa-admin/Api/`
        }
        )
            
        if (resCom.data) {
          const allcomponents2 = resCom.data.map(d => ({        // dynamic cards and routing
            name: d.componentName,
            icon: d.componentIcon,
            desc: d.componentDesc,
            path: d.componentPath,
            status: d.componentStatus,
            disease: d.componentDisease
          }))
          
          cookies.set('components2', JSON.stringify(allcomponents2))

          return Promise.resolve(resCom.data)
        }
      
        return Promise.reject(new Error(resCom.data))
      
      } catch (error) {
        return Promise.reject(new Error(error))
      }	
    },

    async editProfile(data) {
      try {
        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
          formData.append(key, value);
        }

        const res = await axios.post('/updateProfile', 
        formData,
        {
          baseURL: `${axios.defaults.baseURL}diwa-admin/Api/`
        }
        )
            
        if(res.data == true)
          return Promise.resolve(res.data)
        else
          return Promise.reject(new Error(res.data))
      
      } catch (error) {
        return Promise.reject(new Error(error))
      }	
    },

    async updatePassword(data) {
      try {
        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
          formData.append(key, value);
        }

        const res = await axios.post('/changePassword', 
        formData,
        {
          baseURL: `${axios.defaults.baseURL}diwa-admin/Api/`
        }
        )
       
        if(res.data['message'])
          return Promise.reject(new Error(res.data.message))
        else
          return Promise.resolve(res.data)
      
      } catch (error) {
        return Promise.reject(new Error(error))
      }	
    },

    logout() {
      // remove all cookies
      cookies.keys().forEach(cookie => cookies.remove(cookie))

      // returns promise == true to redirect to login page
      return Promise.resolve(true)
    }
  }
})