<template>
  <div class="flex flex-center">
      <q-card class="my-card">  
        <img class="q-pt-md q-pb-md" src="https://www.who.int/images/default-source/health-topics/health-financing/novelcoronavirus-optimized.tmb-1200v.jpg?Culture=en&sfvrsn=755458c4_14">
        <q-separator inset/>
        <q-card-section>
          
          <q-btn class="btn--no-hover absolute" size="lg" round color="primary" 
          href="https://diseasewatch.ph/services/covid19"
          style="top: 0; right: 12px; transform: translateY(-50%);"
        >
          <font-awesome-icon class="my-icon" :icon="['fas', 'virus']" />
        </q-btn>

          <div class="text-h5 q-mt-sm q-mb-xs">COVID-19</div>
          <div class="text-caption text-grey text-justify">
             "Coronavirus disease (COVID-19) is an infectious disease caused by the SARS-CoV-2 virus. Most people infected with the virus will experience mild to moderate respiratory illness and recover without requiring special treatment. However, some will become seriously ill and require medical attention." (Source: <a href="https://www.who.int/health-topics/coronavirus/">WHO</a>)
          </div>
        </q-card-section>
      </q-card>
      
      <q-card class="my-card">
        <img class="q-pt-md q-pb-md" src="https://cdn.theatlantic.com/thumbor/njRpci_ObX-9uWThMXvS8uhcQNg=/0x304:8898x5309/960x540/media/img/mt/2021/06/GettyImages_1263464608/original.jpg">
        <q-separator inset/>
        <q-card-section>
          
        <q-btn class="btn--no-hover absolute" size="lg" round color="primary" 
          href="https://diseasewatch.ph/services/dengue"
          style="top: 0; right: 12px; transform: translateY(-50%);"
        >
          <font-awesome-icon class="my-icon" :icon="['fas', 'mosquito']" />
        </q-btn>
          
          <div class="text-h5 q-mt-sm q-mb-xs">DENGUE</div>
          <div class="text-caption text-grey text-justify">
            "Dengue infection is a disease caused by four dengue serotypes primarily transmitted by the Aedes aegypti mosquito. With the four dengue serotypes, an individual can be infected with dengue as many as four times in a lifetime. Southeast Asia has recorded the most number of dengue cases in the world by far. In the Philippines, EB recorded 106,630 dengue cases in 2019 alone, which was above the epidemic threshold." (Source: <a href="https://ritm.gov.ph/newdenguealgorithm/">RITM, 2022</a>)
          </div>
        </q-card-section>
      </q-card>
      
      <q-card class="my-card">
        <img class="q-pt-md q-pb-md" src="https://cartwrightlaw.com/wp-content/uploads/2019/06/angry-dog-bite-accident-with-teeth-1024x682.jpg">
        <q-separator inset/>
        <q-card-section>

        <q-btn class="btn--no-hover absolute" size="lg" round color="primary" 
          href="#" target="_blank"
          style="top: 0; right: 12px; transform: translateY(-50%);"
        >
          <font-awesome-icon class="my-icon" :icon="['fas', 'dog']" />
        </q-btn>
        <div class="text-h5 q-mt-sm q-mb-xs">RABIES</div>
        <div class="text-caption text-grey text-justify">
          "Rabies is a viral disease that is estimated to cause 59,000 human deaths annually in over 150 countries–including the Philippines. As such, the National Rabies Prevention and Control Program targets the elimination of rabies in the country by the year 2030 and the Department of Agriculture was mandated by the Anti-rabies Act of 2007 to improve and upgrade existing animal rabies laboratory diagnostic capabilities to ensure better services to the people." (Source: <a href="https://ritm.gov.ph/ritm-conducts-3rd-dfat-training/">RITM, 2022</a>)
        </div>
      </q-card-section>

</q-card>

  </div>
</template>

<script>
export default {
  setup () {
  }

}
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap')

body
  background-color: rgb(247, 247, 247)

.title
  font-family: 'Montserrat', sans-serif

img
  height: 275px
  width: 100%

.my-card
  width: 100%
  height: 500px
  max-width: 475px
  margin: 5px
  border-radius: 30px

.my-card:hover 
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.09) 
  border-radius: 30px 

.my-icon:hover
  color: #ffc631

a
  text-decoration: none
  color: black 

a:hover
  text-decoration: none
  font-weight: bold

:deep(.q-btn.btn--no-hover .q-focus-helper)
  display: none

</style>

