
<template>
  <q-page class="flex flex-center bg-grey-3">
    <q-intersection
      v-for="card in JSON.parse(auth.userComponents)"
      :key="card"
      once
      transition="scale"
    >
      <RouterLink :to="card.path">
        <q-card class="my-card">
            <span class="info text-black absolute-top-right q-pa-xs">
              <q-btn class="btn--no-hover" flat round color="dark" icon="info" size="md">
                <q-tooltip
                  class="bg-info q-pa-md text-caption text-black text-weight-bold"
                  anchor="center left" self="center right"
                  max-width="300px"
                >
                  {{ card.desc }}
                </q-tooltip>
              </q-btn>
            </span>
            <q-avatar square size="100px" class="icon-bg absolute-center" text-color="primary"><font-awesome-icon :icon="['fa', card.icon]" size="lg"/></q-avatar>
            <div class="text-subtitle2 text-black q-py-md absolute-bottom text-center text-weight-bold">
              {{ card.name }}
            </div>
        </q-card>
      </RouterLink>
    </q-intersection>
  </q-page>
</template>

<script>
import { useAuthStore } from '@/store'

export default {
  setup () {
    const auth = useAuthStore()
    
    return {
      auth
    }
  }
}
</script>


<style lang="sass" scoped>
.my-card
  width: 275px
  height: 275px
  margin: 10px
  background-color: white
  border-radius: 30px

.my-card:hover
  background-color: #9eb1bc

.icon-bg
  color: black
.my-card
    color: red
.info
  z-index: 1

:deep(.q-btn.btn--no-hover .q-focus-helper)
  display: none
  
</style>
