<template>
  <q-layout view="hHh lpR fFf" class="white">
    <NavbarMain />
      <router-view />    
    <FooterMain />
  </q-layout>
</template>

<script>
import NavbarMain from '@/components/main/NavbarMain.vue'
import FooterMain from '@/components/main/FooterMain.vue'

export default {
  name: 'App',

  components: {
    NavbarMain,
    FooterMain
  },
  
}
</script>

