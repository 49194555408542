import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store';

import HomePage from '@/views/homepage/HomePage.vue'
import ServicesPage from '@/views/services/ServicesPage'



const routes = [
  {
    path: '/',
    name: 'DiWA',
    component: HomePage,
    meta: {
      hideNavBar: true,
      requiresAuth: false
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesPage,
    meta: {
      requiresAuth: true
    },
    children: [
      { 
        path: 'covid19', 
        name: 'COVID-19 Services',
        component: ServicesPage
      },
      { 
        path: 'dengue', 
        name: 'Dengue Services',
        component: ServicesPage
      },
    ]
  },
  {
    path: '/covid19/maps',
    name: 'Spatiotemporal Maps',
    props: true,
    component: () => import('../views/covid19/MapsPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/covid19/forecasts',
    component: () => import('../views/covid19/ForecastsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    },
    children: [
      { 
        path: '', 
        props: true,
        redirect: '/covid19/forecasts/forecasts-report',
      },
      { 
        path: 'forecasts-report', 
        name: 'Forecasts: Situational Reports',
        props: true,
        component: () => import('../components/covid19/forecasts/ForecastReports.vue') 
      },
      { 
        path: 'forecasts-map', 
        name: 'Forecasts: Maps',
        props: true,
        component: () => import('../components/covid19/forecasts/ForecastMaps.vue') 
      }
    ]
  },
  {
    path: '/covid19/trends',
    component: () => import('../views/covid19/TrendsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    },
    children: [
      { 
        path: '', 
        props: true,
        redirect: '/covid19/trends/situational-reports',
      },
      { 
        path: 'situational-reports', 
        name: 'Disease Trends: Situational Reports',
        props: true,
        component: () => import('../components/covid19/trends/SituationalReports.vue') 
      },
      { 
        path: 'new-cases', 
        name: 'Disease Trends: New Cases',
        props: true,
        component: () => import('../components/covid19/trends/NewCases.vue') 
      },
      { 
        path: 'hospitalized-cases', 
        name: 'Disease Trends: Hospitalized',
        props: true,
        component: () => import('../components/covid19/trends/HospitalizedCases.vue') 
      },
      { 
        path: 'death-cases', 
        name: 'Disease Trends: Deaths',
        props: true,
        component: () => import('../components/covid19/trends/DeathCases.vue') 
      },
      { 
        path: 'estimated-r', 
        name: 'Disease Trends: Estimated R',
        props: true,
        component: () => import('../components/covid19/trends/EstimatedR.vue') 
      }
    ]
  },
  {
    path: '/covid19/phylo',
    name: 'Genome-based Epidemiology',
    component: () => import('../views/covid19/PhyloPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/covid19/abm",
    meta: {
      requiresAuth: true
    },
    beforeEnter() {
      location.href = `${window.location.origin}/covid19/abm/abm-webapp/`;
    }
  },
  {
    path: "/covid19/vax",
    meta: {
      requiresAuth: true
    },
    beforeEnter() {
      location.href = `${window.location.origin}/covid19/vax/Main_C/dashboard`;
    }
  },  
  {
    path: "/covid19/wbe",
    meta: {
      requiresAuth: true
    },
    beforeEnter() {
      location.href = 'https://kggo2.shinyapps.io/iWAS/';
    }
  },
  {
    path: "/covid19/triaging",
    name: "Triaging",
    meta: {
      requiresAuth: true
    },
    beforeEnter() {
      location.href = `${window.location.origin}/covid19/triaging/app/dist`;
    }
  },   
  /*
  {
    path: "/dengue/maps",
    name: "Dengue Maps",
    component: () => import('../views/MapsDenguePage.vue'), 
    meta: {
      requiresAuth: false
    }
  },
  */ 
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      requiresAuth: true
    },
    beforeEnter() {
      location.href = `${window.location.origin}/diwa-admin`;
    }
  },
  {
    path: '/profile',
    component: () => import('../views/account/AccountPage.vue'), 
    props: true,
    meta: {
      requiresAuth: true
    },
    children: [
      { 
        path: '', 
        props: true,
        redirect: '/profile/view',
      },
      { 
        path: 'view', 
        name: 'Profile',
        props: true,
        component: () => import('../components/account/AccountView.vue'), 
      },
      { 
        path: 'edit', 
        name: 'Edit Profile',
        props: true,
        component: () => import('../components/account/AccountEdit.vue'), 
      },
      { 
        path: 'password', 
        name: 'Change Password',
        props: true,
        component: () => import('../components/account/PasswordUpdate.vue'), 
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/account/LoginPage.vue'),
    meta: {
      hideNavBar: true,
      requiresAuth: false
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter() {
      const auth = useAuthStore()
      auth.logout().then((val) => {
        if (val) {
          router.push('/login')
          router.go(1)
        }
        else {
          console.log("There's a problem with logging you out.")
          router.go(0)
        }
      })
    },
    meta: {
      hideNavBar: true,
      requiresAuth: false
    }
  },
  {
    path: '/:catchAll(.*)*',
    name: 'Error',
    component: () => import('../views/error/ErrorPage.vue'),
    meta: {
      hideNavBar: true,
      requiresAuth: false
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  const auth = useAuthStore()
  auth.$reset()   // resets pinia to initial state - retrieved stored data from localstorage
  
  localStorage.setItem('returnUrl', to.fullPath)    // to redirect back to previous page after successful login
  
  let services_paths = ['/services/covid19', '/services/dengue']
  let profile_paths = ['/profile', '/profile/view', '/profile/edit', '/profile/password']
  let admin_paths = ['/admin']


  // if next page requires user to login
  if(to.meta.requiresAuth) {
    if (!auth.isAuthorized) {
      // if unauthorized user visits guarded pages
      next('/login')
    } else {
      // if authorized
      if (JSON.parse(auth.userComponents).map(d => d.path).find(e => to.fullPath.includes(e))) {
        // proceed if component is accessible by user
        next()
      } else {
        // move on to next hook in the pipeline (set as returnUrl or /services within login component)
        if (services_paths.includes(to.fullPath)) {
          // to proceed to services page smoothly (no component access review needed)
          next()
        //  // to proceed to services page smoothly (no component access review needed)
        } else if (profile_paths.includes(to.fullPath)) {
        // to proceed to profile page smoothly (no component access review needed)
          next()
        } else if (admin_paths.includes(to.fullPath)) {
          // to proceed to admin pages smoothly (no component access review needed)
          next()
        } else {
          // back to services if component is not accessible
          next('/services/covid19')
        }
      }
    }
  } else {
    if(to.fullPath == '/login' && auth.isAuthorized) {
      // if user goes to /login while already logged in
      next(auth.returnUrl)
    } else {
      // initial clean login page or after logging out
      next()
    }
  }
  
})

export default router
