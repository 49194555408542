<template>
  <q-layout view="hHh lpR fFf">
    <q-header class="bg-grey-2 text-black">
      <q-toolbar>
        <q-toolbar-title>
          <div class="q-pa-sm header">
            <div class="row">

              <!-- DiWA logo -->
              <div class="q-pa-sm col-lg-3 col-xs-12">
                <router-link to="/">
                  <q-avatar>
                    <img src="@/assets/logos/diwa.png">
                  </q-avatar>
                  <strong class="title"><span class="main_title">DiWA:</span> Disease Watch and Analytics</strong>
                </router-link>
              </div>
              <!-- End of DiWA logo -->   
              
              <q-space />
              
              <div class="q-pa-none col-lg-6 col-xs-12">
                <q-tabs 
                v-model="tab" 
                indicator-color="transparent" 
                active-color="accent"
                align="justify"
                class="sections tabs--no-hover"
                color="black"
                >
                <q-tab :ripple="false" name="about">About the App</q-tab>
                <q-tab :ripple="false" name="services">Our Services</q-tab>
                <q-tab :ripple="false" name="diseases">Disease Focus</q-tab>
              </q-tabs>
            </div>
            
            <q-space />
            
            <div class="q-pa-sm col-lg-3 col-xs-12 q-gutter-sm">
              <q-btn v-if="!auth.isAuthorized" class="float-right" unelevated size="md" text-color="white" color="primary" icon-right="login" label="Sign In" to="/login"/>
              <q-btn v-else class="float-right" unelevated size="md" text-color="white" color="accent" icon-right="logout" label="Sign Out" to="/logout"/>
              <q-btn v-if="auth.isAuthorized" class="float-right" unelevated size="md" text-color="white" color="primary" label="Go to the App" to="/services"/>
            </div>
          </div>
        </div>
      </q-toolbar-title>
    </q-toolbar>
  </q-header>
  
  <q-page-container>
    <q-page class="row items-stretch">
      <q-tab-panels v-model="tab" 
      animated 
      vertical
      class="col-12"
      draggable="false"
      >
      <q-tab-panel name="about">
        <q-img class="absolute-bottom bg-analytics"
        src="@/assets/homepage/analytics.png"
        fit="cover"
        />
        <!-- todo: fix for mobile view -->
        <div class="flex flex-center">
          <div class="row about">
            <div class="col-12">
              <span class=" text-primary text-h1">
                <img src="@/assets/logos/diwa2.png" style="width: 180px">
                <span class="text-h1 text-weight-bolder">
                  Di<span class="text-accent">W</span>A
                </span>
              </span>
            </div>
            <div class="col-12 q-py-lg">
              <span class="text-weight-bolder text-h2">Disease Watch and Analytics</span>
            </div>
            <div class="col-12 text-h4">
              A virtual planning tool and integrative platform 
              <br />to empower decision-makers
              <br />for disease management in Mindanao.
            </div>
          </div>
        </div>
      </q-tab-panel>
      
      <q-tab-panel name="services">
        <div class="my-carousel">
          <HomeCarousel />
        </div>
      </q-tab-panel>
      

      <q-tab-panel name="diseases">
        <div class="diseases-cards">
          <HomeDiseases />
        </div>
      </q-tab-panel>

    </q-tab-panels>
  </q-page>
</q-page-container>


<q-footer reveal class="bg-grey-2 text-black">
  <q-toolbar>
    <q-toolbar-title>
      <div class="q-pa-xs footer">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <div class="row q-pa-xs items-start float-left">
              <ul>
                <li><a href="https://upmin-dsshealth.up.edu.ph/the-team" target="_blank">The Team</a></li>
                <li><a href="https://upmin-dsshealth.up.edu.ph/home_1/contact-us" target="_blank">Contact Us</a></li>
              </ul>
            </div>
            <div class="row q-pa-xs items-start float-left">
              <ul>
                <li><a href="#">Terms and Conditions</a></li>
                <li><a href="#">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-xs-12 vertical-middle">
            <div class="row q-pa-sm">
              <q-btn class="btn--no-hover"
              flat
              no-ripple
              href="https://upmin-dsshealth.up.edu.ph/" target="_blank"
              >
              <img class="logos" 
              style="height: 30px; max-width: 40px"
              src="@/assets/logos/amdabidss.png"
              />
              <q-tooltip           
              transition-show="scale"
              class="bg-info text-primary text-weight-bold"
              >
              Center for Applied Modelling, Data Analytics, and Bioinformatics for Decision Support Systems in Health
            </q-tooltip>
          </q-btn>
          
          <q-space />
          
          <q-btn class="btn--no-hover"
          flat
          no-ripple
          href="https://www2.upmin.edu.ph/" target="_blank"
          >
          <img class="logos" 
          style="height: 25px; max-width: 30px"
          src="@/assets/logos/upmindanao.png"
          />
          <q-tooltip           
          transition-show="scale"
          class="bg-info text-primary text-weight-bold"
          >
          University of the Philippines Mindanao
        </q-tooltip>
      </q-btn>
      
      <q-space />
      
      <q-btn class="btn--no-hover"
      flat
      no-ripple
      href="https://mcm.edu.ph/" target="_blank"
      >
      <img class="logos" 
      style="height: 25px; max-width: 65px"
      src="@/assets/logos/mcm.png"
      />
      <q-tooltip           
      transition-show="scale"
      class="bg-info text-primary text-weight-bold"
      >
      Mapúa Malayan Colleges Mindanao
    </q-tooltip>
  </q-btn>
  
  <q-space />
  
  <q-btn class="btn--no-hover"
  flat
  no-ripple
  href="https://www.dost.gov.ph/" target="_blank"
  >
  <img class="logos" 
  style="height: 25px; max-width: 25px"
  src="@/assets/logos/dost.png"
  />
  <q-tooltip           
  transition-show="scale"
  class="bg-info text-primary text-weight-bold"
  >
  Department of Science and Technology
</q-tooltip>
</q-btn>

<q-space />

<q-btn class="btn--no-hover"
flat
no-ripple
href="https://www.pchrd.dost.gov.ph/" target="_blank"
>
<img class="logos" 
style="height: 25px; max-width: 25px"
src="@/assets/logos/pchrd.png"
/>
<q-tooltip           
transition-show="scale"
class="bg-info text-primary text-weight-bold"
>
Philippine Council for Health Research and Development
</q-tooltip>
</q-btn>

<q-space />

<q-btn class="btn--no-hover"
flat
no-ripple
href="https://s4cp.dost.gov.ph/" target="_blank"
>
<img class="logos" 
style="height: 25px; max-width: 27px"
src="@/assets/logos/s4cp.png"
/>
<q-tooltip           
transition-show="scale"
class="bg-info text-primary text-weight-bold"
>
Science for Change Program (S4CP)
</q-tooltip>
</q-btn>

<q-space />

<q-btn class="btn--no-hover"
flat
no-ripple
href="https://s4cp.dost.gov.ph/programs/nicer/nicer-projects/" target="_blank"
>
<img class="logos" 
style="height: 25px; max-width: 65px"
src="@/assets/logos/nicer.png"
/>
<q-tooltip           
transition-show="scale"
class="bg-info text-primary text-weight-bold"
>
Niche Centers in the Regions (NICER) for R&D
</q-tooltip>
</q-btn>

</div>  
</div>
<div class="col-md-3 col-xs-12">
  <div class="row q-pa-sm items-start float-right">
    <q-btn class="btn--no-hover" size="md" flat round color="primary" href="https://www.facebook.com/AMDABiDSSHealth" target="_blank"><font-awesome-icon :icon="['fab', 'facebook']" /></q-btn>
    <q-btn class="btn--no-hover" size="md" flat round color="primary" href="https://www.instagram.com/amdabidsshealth/" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" /></q-btn>
    <q-btn class="btn--no-hover" size="md" flat round color="primary" href="https://twitter.com/AMDABIDSSHealth" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']" /></q-btn>
    <q-btn class="btn--no-hover" size="md" flat round color="primary" href="mailto:nicer.upmin@up.edu.ph " target="_blank"><font-awesome-icon :icon="['fa', 'envelope']" /></q-btn>          
  </div>
</div>
</div>
</div>
</q-toolbar-title>
</q-toolbar>
</q-footer>

</q-layout>
</template>

<script>
import { ref } from 'vue'
import HomeCarousel from '@/components/homepage/HomeCarousel.vue'
import HomeDiseases from '@/components/homepage/HomeDiseases.vue'
import { useAuthStore } from '@/store';

export default {
  components: {
    HomeCarousel,
    HomeDiseases
  },
  setup () {
    const auth = useAuthStore()
    
    return {
      tab: ref('about'),
      auth
    }
  }
}
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap')

.header
  font-family: 'Montserrat', sans-serif

.title
  font-family: 'Montserrat', sans-serif
  font-weight: normal
  font-size: 15px
  padding: 0px 5px

.main_title
  font-weight: 1000
  font-style: italic

.bg-analytics
  opacity: 0.65

.sections
  font-size: 16px
  font-weight: bold

.about
  padding-top: 100px
  padding-bottom: 50px
  width: 80%
  text-align: center
  font-family: 'Lato', sans-serif
  z-index: 0

.my-carousel
  max-width: 700px
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

.diseases-cards
  padding-top: 20px
  padding-bottom: 50px

.my-card
  width: 100%
  max-width: 600px
  margin: 10px
  background-color: white

.logos
  filter: grayscale(100%)

.logos:hover
  filter: none

.footer
  text-align: left
  font-family: 'Montserrat', sans-serif
  font-size: 10px

ul
  list-style-type: none

a
  text-decoration: none
  color: black 

a:hover
  text-decoration: none
  font-weight: bold

:deep(.q-btn.btn--no-hover .q-focus-helper)
  display: none

:deep(.q-tabs.tabs--no-hover .q-focus-helper)
  display: none

.fa-facebook:hover
  color: #ffc631

.fa-instagram:hover
  color: #ffc631

.fa-twitter:hover
  color: #ffc631

.fa-envelope:hover
  color: #ffc631

</style>
