import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from '@/App.vue'
import router from '@/router'
import '@/interceptors/axios'
import VueCookies from 'vue3-cookies'

import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from '@/quasar-user-options'
import VuePdf from 'vue3-pdfjs'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faFacebook, faTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faInstagram, faFacebook, faTwitter, faGoogle, fas)



const app = createApp(App)
const pinia = createPinia()

app.use(Quasar, {
    plugins: {
      Notify
    },
    config: {
      notify: { quasarUserOptions }
    }
  })
app.component("font-awesome-icon", FontAwesomeIcon)
app.use(pinia)
app.use(router)
app.use(VuePdf)
app.use(VueCookies, {
    expireTimes: "30s",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});
app.mount('#app')