<template>
    <q-footer       
      v-if="!route.meta.hideNavBar"
      bordered 
      class="bg-white text-black"
    >
        <q-toolbar style="min-height: 25px" no-padding>
            <q-toolbar-title>
              <div class="footer-center absolute-center">
                  <b><i>DiWa</i></b> v1.0.0
              </div>
            </q-toolbar-title>
        </q-toolbar>
    </q-footer>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap')

.footer-center
  text-align: center
  font-family: 'Lato', sans-serif
  font-size: 8px

</style>