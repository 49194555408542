<template>
  <q-header 
  v-if="!route.meta.hideNavBar"
  bordered 
  class="bg-white text-black"
  >
  <q-toolbar>
    <q-toolbar-title>
      <div class="q-pa-sm">
        <div class="row">
          
          <!-- DiWA logo -->
          <div class="q-pa-sm col-lg-3 col-xs-12">
            <router-link to="/">
              <q-avatar>
                <img src="@/assets/logos/diwa.png">
              </q-avatar>
              <strong class="title"><span class="main_title">DiWA:</span> Disease Watch and Analytics</strong>
            </router-link>
          </div>
          <!-- End of DiWA logo -->   
          
          <q-space />
          
          
          <div class="q-pa-sm col-lg-4 col-xs-12 text-center">
            <q-toolbar-title>
              <strong class="heading">{{ route.name }}</strong>
            </q-toolbar-title>
          </div>
          
          <q-space />
          
          <div class="q-pa-sm">
            <q-btn-dropdown
            class="btn--no-hover"
            :label="activeDisease"
            flat
            size="md"
            dropdown-icon="filter_center_focus"
            >
            <q-list>
              <q-item clickable v-close-popup to="/services/covid19" @click="setDisease('COVID-19')">
                <q-item-section>
                  COVID-19
                </q-item-section>
              </q-item>  
            </q-list>
            <!--
            <q-list>
              <q-item clickable v-close-popup to="/services/dengue" @click="setDisease('DENGUE')">
                <q-item-section>
                  DENGUE
                </q-item-section>
              </q-item>  
            </q-list>
            -->
        </q-btn-dropdown>
        </div>
          
          <div class="q-pa-sm">
            <q-btn-dropdown
            class="btn--no-hover"
            v-if="route.name!='Services'"
            dropdown-icon="apps"
            flat
            size="md"
            >
            <q-list
            v-for="item in JSON.parse(auth.userComponents)"
            :key="item" >
            
            <q-item clickable v-close-popup :to="item.path">
              <q-item-section avatar>
                <font-awesome-icon :icon="['fa', item.icon]" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ item.name }}</q-item-label>
              </q-item-section>
            </q-item> 
            
          </q-list>
        </q-btn-dropdown>
      </div>
      
      <!--
      <div class="q-pa-sm">
            <q-btn-dropdown
            class="btn--no-hover"
            v-if="route.name!='Services'"
            dropdown-icon="menu_book"
            flat
            size="md"
            >
            <q-list>
              <q-item clickable v-close-popup @click="notes = true">
                <q-item-section>
                  Technical Notes
                </q-item-section>
              </q-item>  
            </q-list>
            <q-list>
              <q-item clickable v-close-popup @click="manual = true">
                <q-item-section>
                  User Manual
                </q-item-section>
              </q-item>  
            </q-list>
        </q-btn-dropdown>
      </div>
      -->

      <div class="q-pa-sm">
        <q-btn-dropdown
        class="btn--no-hover"
        flat
        size="md"
        >
        <template v-slot:label>
          <div class="row items-center no-wrap">
            <q-avatar size="md"><font-awesome-icon :icon="['fa', 'user']" /></q-avatar>
            <q-item-label> {{ auth.name }} </q-item-label>
          </div>
        </template>
        
        <q-list>
          <q-item to="/profile">
            <q-item-section>
              Profile
            </q-item-section>
          </q-item>  
        </q-list>
        <q-list>
          <q-item to="/logout">
            <q-item-section>
              Logout
            </q-item-section>
          </q-item>  
        </q-list>
      </q-btn-dropdown>
    </div>
  </div>
</div>
</q-toolbar-title>
</q-toolbar>
</q-header>


<q-dialog v-model="manual">
  <q-card>
    <q-card-section>
      <div class="text-h6">User Manual</div>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <p v-for="n in 15" :key="n">Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum repellendus sit voluptate voluptas eveniet porro. Rerum blanditiis perferendis totam, ea at omnis vel numquam exercitationem aut, natus minima, porro labore.</p>
    </q-card-section>

    <q-card-actions align="right">
    </q-card-actions>
  </q-card>
</q-dialog>

<q-dialog v-model="notes">
  <q-card>
    <q-card-section>
      <div class="text-h6">Technical Notes</div>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <p v-for="n in 15" :key="n">Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum repellendus sit voluptate voluptas eveniet porro. Rerum blanditiis perferendis totam, ea at omnis vel numquam exercitationem aut, natus minima, porro labore.</p>
    </q-card-section>

    <q-card-actions align="right">
    </q-card-actions>
  </q-card>
</q-dialog>


</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/store'
import { useCookies } from "vue3-cookies"

export default {  
  setup () {
  //setup (props) {
    

    const { cookies } = useCookies()
    const route = useRoute()
    //const router = useRouter()
    const auth = useAuthStore()
    //const { activeDisease } = storeToRefs(auth)
    const activeDisease = ref('COVID-19')
    const manual = ref(false)
    const notes = ref(false)
    
    function setDisease(selected) {
      cookies.set('activeDisease', selected)
      console.log(cookies.get('activeDisease'))
      //auth.activeDisease = selected
      activeDisease.value = selected
      //auth.switchDisease(selected)
      auth.getComponents()
    }

    return {
      route,
      auth,
      activeDisease,
      manual,
      notes,
      setDisease
    }
  }
}
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap')

a
  text-decoration: none
  color: black 

a:hover
  text-decoration: none

.heading
  font-family: 'Montserrat', sans-serif

.title
  font-family: 'Montserrat', sans-serif
  font-weight: normal
  font-size: 15px
  padding: 0px 5px

.main_title
  font-weight: 1000
  font-style: italic

:deep(.q-btn.btn--no-hover .q-focus-helper)
  display: none

</style>