<template>
  <q-page-container>
      <HomeMain />
  </q-page-container>
</template>

<script>
// @ is an alias to /src
import HomeMain from '@/components/homepage/HomeMain.vue'

export default {
  components: {
    HomeMain
  }
}
</script>
