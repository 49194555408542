<template>
  <div class="q-pa-xs">
    <q-carousel
      v-model="slide"
      :autoplay="autoplay"     
      infinite
      swipeable
      animated
      control-color="info"
      navigation
      padding
      arrows
      class="text-primary rounded-borders"
    >
      <template v-slot:control>
        <q-carousel-control
          position="top-right"
          :offset="[18, 18]"
          class="text-dark rounded-borders"
        >
          <q-toggle flat size="xl" checked-icon="play_arrow" unchecked-icon="pause" color="info" v-model="autoplay" />
        </q-carousel-control>

      </template>

      <q-carousel-slide name="1" class="column no-wrap flex-center">
        <div class="text-center text-black">
            <q-avatar size="200px" text-color="primary">
                <font-awesome-icon :icon="['fa', 'house-laptop']"/>
            </q-avatar>
            <p class="content-title">COVID-19 Dynamics Simulation</p>
            <p class="content-desc" width="500px">An Agent-based Model of COVID-19 dynamics incorporating Philippine COVID-19 interventions</p>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="2" class="column no-wrap flex-center">
        <div class="text-center text-black">
            <q-avatar size="200px" text-color="primary">
                <font-awesome-icon :icon="['fa', 'chart-column']" />
            </q-avatar>
            <p class="content-title">Disease Trends</p>
            <p class="content-desc">Statistical Analysis and Data Visualization of the COVID-19 cases across different regions in Mindanao</p>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="3" class="column no-wrap flex-center">
        <div class="text-center text-black">
            <q-avatar size="200px" text-color="primary">
                <font-awesome-icon :icon="['fa', 'chart-line']" />
            </q-avatar>
            <p class="content-title">Forecasts</p>
            <p class="content-desc">COVID-19 Case Projection through Mathematical Modeling to empower decision-makers for pandemic management in Mindanao</p>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="4" class="column no-wrap flex-center">
        <div class="text-center text-black">
            <q-avatar size="200px" text-color="primary">
                <font-awesome-icon :icon="['fa', 'dna']" />
            </q-avatar>
            <p class="content-title">Genome-based Epidemiology</p>
            <p class="content-desc">Reconstruction of the evolutionary patterns of SARS-CoV-2 in understanding
            the location and spreading rate of COVID-19 cases through Phylogenetics, and
            Phylogeographic Modeling</p>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="5" class="column no-wrap flex-center">
        <div class="text-center text-black">
            <q-avatar size="200px" text-color="primary">
                <font-awesome-icon :icon="['fa', 'map']" />
            </q-avatar>
            <p class="content-title">Spatiotemporal Maps</p>
            <p class="content-desc">Visualization and Analysis of COVID-19 across different regions in Mindanao using Geographic Information System (GIS)</p>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="6" class="column no-wrap flex-center">
        <div class="text-center text-black">
            <q-avatar size="200px" text-color="primary">
                <font-awesome-icon :icon="['fa', 'syringe']" />
            </q-avatar>
            <p class="content-title">Vaccination Rollout System</p>
            <p class="content-desc">A Decision Support System for the optimal location-allocation of vaccines and the
            COVID-19 vaccination rollout of individuals</p>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="7" class="column no-wrap flex-center">
        <div class="text-center text-black">
            <q-avatar size="200px" text-color="primary">
                <font-awesome-icon :icon="['fa', 'hospital-user']" />
            </q-avatar>
            <p class="content-title">Triaging</p>
            <p class="content-desc">A Triaging strategy incorporating survival analysis to aid health professionals in prognosis evaluation</p>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="8" class="column no-wrap flex-center">
        <div class="text-center text-black">
            <q-avatar size="200px" text-color="primary">
                <font-awesome-icon :icon="['fa', 'droplet']" />
            </q-avatar>
            <p class="content-title">Wastewater-based Epidemiology</p>
            <p class="content-desc">Estimation of the prevalence of COVID-19 infection in Mindanao using community-level surveillance and genetic tracking of SARS-CoV-2 and its mutations in water systems</p>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  setup () {
    return {
      slide: ref('1'),
      autoplay: ref(true)
    }
  }
}
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap')

.content-title
  font-size: 24px
  font-weight: bold
  font-family: 'Lato', sans-serif

.content-desc
  font-size: 16px
  font-family: 'Lato', sans-serif

</style>

